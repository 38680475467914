import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/(app)/[language=language]": [6,[2,3]],
		"/(app)/[language=language]/booking/details/[id=uuid]": [8,[2,3]],
		"/(app)/[language=language]/booking/details/[id=uuid]/move": [~9,[2,3]],
		"/(app)/[language=language]/booking/details/[id=uuid]/success": [10,[2,3]],
		"/(app)/[language=language]/booking/lead/success": [11,[2,3]],
		"/(app)/[language=language]/booking/welcome": [12,[2,3]],
		"/(app)/[language=language]/booking/[slug]": [~7,[2,3]],
		"/(app)/[language=language]/enter-payment-info/[mailToken]": [13,[2,3]],
		"/(app)/[language=language]/sign-up/facility": [14,[2,3,4]],
		"/(app)/[language=language]/sign-up/membership": [15,[2,3,4]],
		"/(app)/[language=language]/sign-up/order-overview": [16,[2,3,4]],
		"/(app)/[language=language]/sign-up/personal-data": [17,[2,3,4]],
		"/(app)/[language=language]/sign-up/services": [18,[2,3,4]],
		"/(app)/[language=language]/sign-up/welcome": [19,[2,3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';